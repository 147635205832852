import {Component, Input} from '@angular/core';
import {InputAssist} from '../input-assist';


@Component({
  selector: 'app-input-dropdown',
  templateUrl: './dropdown.component.html'
})

export class DropdownComponent extends InputAssist {

  @Input()
  placeholder: string;
}

