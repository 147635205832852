import {CardComponent} from './components/card/card.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {DropdownComponent} from './components/input/dropdown/dropdown.component';
import {RadioComponent} from './components/input/radio/radio.component';
import {CardHeaderComponent} from './components/card/card-header/card-header.component';
import {CardContentComponent} from './components/card/card-content/card-content.component';
import {InputManagerComponent} from './components/input/input-manager.component';
import {InputDirective} from './components/input/input.directive';
import {LanguageComponent} from './components/language/language.component';
import {LabelComponent} from './components/input/label/label.component';
// import {OptionComponent} from './components/input/option/option.component';
import {ButtonComponent} from './components/input/button/button.component';
import {TextComponent} from './components/input/text/text.component';


export const COMPONENTS = [
  CardComponent,
  CardHeaderComponent,
  CardContentComponent,

  InputManagerComponent,
  InputDirective,

  ProgressBarComponent,
  LanguageComponent
];

export const ENTRY_COMPONENTS = [
  ButtonComponent,
  DropdownComponent,
  LabelComponent,
  RadioComponent,
  // OptionComponent,
  TextComponent
];


