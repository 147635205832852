import { Action } from '@ngrx/store';
import { NavigationItem } from 'src/app/modules/layout-module/models/navigation-item';

export enum ActionTypes {
  LOAD_REQUEST = '[Navigation] Load Request',
  LOAD_FAILURE = '[Navigation] Load Failure',
  LOAD_SUCCESS = '[Navigation] Load Success',

  SHOW_ALERT = '[ALERT] Show Alert'
}

export class LoadNavigationsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
}

export class LoadNaviationsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadNaviationsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: Array<NavigationItem> }) {}
}

export class ShowAlertAction implements Action {
  readonly type = ActionTypes.SHOW_ALERT;

  constructor(public payload: { message: string }) {}
}

export type Actions =
  | LoadNavigationsRequestAction
  | LoadNaviationsFailureAction
  | LoadNaviationsSuccessAction
  | ShowAlertAction;
