import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';

import { featureAdapter, State } from './state';
import { NavigationItem } from 'src/app/modules/layout-module/models/navigation-item';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getIsShowAlert = (state: State): boolean => state.showAlert;

export const selectNavigationState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('layoutState');

export const selectAllNavigationItems: (
  state: object
) => NavigationItem[] = featureAdapter.getSelectors(selectNavigationState)
  .selectAll;

export const selectError: MemoizedSelector<object, any> = createSelector(
  selectNavigationState,
  getError
);

export const selectNavigationItemIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectNavigationState, getIsLoading);

export const showAlert: MemoizedSelector<object, boolean> = createSelector(
  selectNavigationState,
  getIsShowAlert
);
