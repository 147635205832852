import {Component, Input} from '@angular/core';
import {InputAssist} from '../input-assist';


@Component({
  selector: 'app-input-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})

export class LabelComponent extends InputAssist {

  @Input()
  placeholder: string;

  ngOnInit(): void {
    super.ngOnInit();
    if (this.default != null) {
      this.setText(this.default);
    }
  }

  setText(text: string) {
    this.values[0].value = text;
    this.onValueGiven.emit(this.values[0]);
  }
}

