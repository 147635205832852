import {Component, OnInit} from '@angular/core';
import {Question} from '../../models/question';
import {Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {
  selectError,
  selectIsCompletedSurvey,
  selectIsLastQuestion,
  selectIsLoadingRetry,
  selectIsLoadingSurvey,
  selectLanguage,
  selectPreviousQuestiom,
  selectProgressLength,
  selectQuestion,
  selectQuestionIndex, selectShowError,
  selectSubmittedIndex,
  selectUnsubmittedInddex
} from '../../store/selectors';
import {QuestionStoreActions} from '../../store';
import {InputValue} from '../../../../shared/modules/input';
import {SurveyState} from '../../store/state';
import {Language} from '../../../../app.language';
import {TranslateService} from '@ngx-translate/core';
import {Survey} from '../../services/survey-data.service';


@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html'
})

export class SurveyComponent implements OnInit {

  isLoadingSurvey$: Observable<boolean>;
  isLoadingRetry$: Observable<boolean>;

  isCompleted$: Observable<boolean>;
  isLastQuestion$: Observable<boolean>;

  progressLength$: Observable<number>;
  questionIndex$: Observable<number>;
  submittedIndex$: Observable<number>;
  unsubmittedIndex$: Observable<number>;

  answer: InputValue;
  question$: Observable<Question>;
  previousQuestion$: Observable<Question>;

  language$: Observable<Language>;


  constructor(public store$: Store<SurveyState>,
              private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.isLoadingSurvey$ = this.store$.pipe(select(selectIsLoadingSurvey));
    this.isLoadingRetry$ = this.store$.pipe(select(selectIsLoadingRetry));

    this.isCompleted$ = this.store$.pipe(select(selectIsCompletedSurvey));
    this.isLastQuestion$ = this.store$.pipe(select(selectIsLastQuestion));

    this.progressLength$ = this.store$.pipe(select(selectProgressLength));
    this.questionIndex$ = this.store$.pipe(select(selectQuestionIndex));
    this.submittedIndex$ = this.store$.pipe(select(selectSubmittedIndex));
    this.unsubmittedIndex$ = this.store$.pipe(select(selectUnsubmittedInddex));

    this.question$ = this.store$.pipe(select(selectQuestion));
    this.previousQuestion$ = this.store$.pipe(select(selectPreviousQuestiom));

    this.language$ = this.store$.pipe(select(selectLanguage));
    this.language$.subscribe(language => {
      this.changeLanguage(language);
    });
  }

  changeLanguage(language: Language): void {
    this.translate.use(language);

    this.store$.dispatch(
      new QuestionStoreActions.ChangeLanguageRequestAction(
        {language: language}));
  }

  setAnswerGiven(answer: InputValue): void {
    if (answer !== null) {
      this.answer = answer;
    }
  }

  setNextQuestion(answer: InputValue = this.answer): void {
    if (answer != null) {
      this.store$.dispatch(
        new QuestionStoreActions.NextQuestionAction(
          {answer: answer}));
    }

    this.language$.subscribe(language => {
      if (this.translate.currentLang !== language) {
        this.changeLanguage(language);
      }
    });
  }

  setPreviousQuestion(): void {
    this.store$.dispatch(
      new QuestionStoreActions.PreviousQuestionAction());
  }

  setResetSurvey(): void {
    this.store$.dispatch(
      new QuestionStoreActions.ResetSurveyAction());
  }

  setCompletedSurvey(): void {
    this.store$.dispatch(
      new QuestionStoreActions.SubmitAnswersRequestAction());
  }

  setRetrySubmit(): void {
    const surveys = JSON.parse(window.localStorage.getItem('surveys')) as Array<Survey>;

    surveys.forEach(survey =>
      this.store$.dispatch(
        new QuestionStoreActions.RetrySubmitRequestAction({
            surveys: surveys,
            survey: survey
          }
        )
      )
    );
  }

  // setRetrySubmitSurveys(): void {
  //   this.store$.dispatch(
  //     new QuestionStoreActions.RetrySubmitSurveysRequestAction({
  //         surveys: JSON.parse(window.localStorage.getItem('surveys')) as Array<Survey>
  //       }
  //     )
  //   );
  // }

  setResetsubmittedIndex(): void {
    this.store$.dispatch(
      new QuestionStoreActions.ResetSubmittedIndexAction());
  }
}
