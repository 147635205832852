import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NavigationItem } from 'src/app/modules/layout-module/models/navigation-item';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss']
})
export class NavigationItemComponent implements OnInit {
  @Input()
  navigationItem: NavigationItem;

  @Output()
  navigationItemSelected: EventEmitter<NavigationItem> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onNavigationItemSelected(navigationItem: NavigationItem): void {
    this.navigationItemSelected.emit(navigationItem);
  }
}
