import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import * as fromShared from './index';
import {ClarityModule} from '@clr/angular';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, ClarityModule],
  declarations: [
    fromShared.COMPONENTS,
    fromShared.ENTRY_COMPONENTS
  ],
  exports: [
    TranslateModule,
    fromShared.COMPONENTS,
    fromShared.ENTRY_COMPONENTS
  ],
  entryComponents: [
    fromShared.ENTRY_COMPONENTS
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
