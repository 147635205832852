import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-card',
  templateUrl: './card.component.html'
})

export class CardComponent implements OnInit {

  ngOnInit(): void {}
}
