import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([])
    // StoreDevtoolsModule.instrument({
    //   maxAge: 25 // Retains last 25 states
    // })
  ],
  declarations: []
})
export class RootStoreModule {}
