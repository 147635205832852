import {Action} from '@ngrx/store';
import {Question} from '../models/question';
import {InputValue} from '../../../shared/modules/input';
import {Language} from '../../../app.language';
import {Survey} from '../services/survey-data.service';


export enum ActionTypes {

  GET_AVAILABILITY = '[AVAILABILITY] Get',
  SET_AVAILABILITY = '[AVAILABILITY] Set',

  LOAD_QUESTIONS_REQUEST = '[LOAD] Load Request',
  LOAD_QUESTIONS_FAILED = '[LOAD] Load Failed',
  LOAD_QUESTIONS_SUCCESS = '[LOAD] Load Success',

  CHANGE_LANGUAGE_REQUEST = '[LANGUAGE] Change Language Request',
  CHANGE_LANGUAGE_FAILED = '[LANGUAGE] Change Language Failed',
  CHANGE_LANGUAGE_SUCCESS = '[LANGUAGE] Change Language Success',

  NEXT_QUESTION = '[QUESION] Next',
  PREVIOUS_QUESTION = '[QUESION] Previous',

  RESET_SURVEY = '[SURVEY] Reset',

  SUBMIT_ANSWERS_REQUEST = '[ANSWERS] Submit Request',
  SUBMIT_ANSWERS_FAILED = '[ANSWERS] Submit Failed',
  SUBMIT_ANSWERS_SUCCESS = '[ANSWERS] Submit Success',

  SHOW_MODAL = '[MODAL] Show',
  HIDE_MODAL = '[MODAL] Hide',

  RETRY_SUBMIT_REQUEST = '[RETRY] Submit Request',
  RETRY_SUBMIT_FAILED = '[RETRY] Submit Failed',
  RETRY_SUBMIT_SUCCESS = '[RETRY] Submit Success',

  // RETRY_SUBMIT_SURVEYS_REQUEST = '[RETRY] Submit Surveys Request',
  // RETRY_SUBMIT_SURVEYS_FAILED = '[RETRY] Submit Surveys Failed',
  // RETRY_SUBMIT_SURVEYS_SUCCESS = '[RETRY] Submit Surveys Success',

  RESET_SUBMITTED_INDEX = '[SUBMITTED] Reset',
}

export class RequestAvailabilityAction implements Action {
  readonly type = ActionTypes.GET_AVAILABILITY;
}

export class SuccessAvailabilityAction implements Action {
  readonly type = ActionTypes.SET_AVAILABILITY;
  constructor(public payload: { isAvailable: boolean }) {}
}

export class LoadQuestionsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_QUESTIONS_REQUEST;
  constructor(public payload: { language: Language }) {}
}

export class LoadQuestionsFailedAction implements Action {
  readonly type = ActionTypes.LOAD_QUESTIONS_FAILED;
  constructor(public payload: { error: string }) {}
}

export class LoadQuestionsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_QUESTIONS_SUCCESS;
  constructor(public payload: { questions: Array<Question> }) {}
}


export class ChangeLanguageRequestAction implements Action {
  readonly type = ActionTypes.CHANGE_LANGUAGE_REQUEST;
  constructor(public payload: { language: Language }) {}
}

export class ChangeLanguageFailedAction implements Action {
  readonly type = ActionTypes.CHANGE_LANGUAGE_FAILED;
  constructor(public payload: { error: any }) {}
}

export class ChangeLanguageSuccessAction implements Action {
  readonly type = ActionTypes.CHANGE_LANGUAGE_SUCCESS;
  constructor(public payload: { questions: Array<Question> }) {}
}


export class NextQuestionAction implements Action {
  readonly type = ActionTypes.NEXT_QUESTION;
  constructor(public payload: { answer: InputValue }) {}
}

export class PreviousQuestionAction implements Action {
  readonly type = ActionTypes.PREVIOUS_QUESTION;
}


export class ResetSurveyAction implements Action {
  readonly type = ActionTypes.RESET_SURVEY;
}


export class SubmitAnswersRequestAction implements Action {
  readonly type = ActionTypes.SUBMIT_ANSWERS_REQUEST;
}

export class SubmitAnswersFailedAction implements Action {
  readonly type = ActionTypes.SUBMIT_ANSWERS_FAILED;
  constructor(public payload: {
    error: string,
    survey: Survey }) {
  }
}

export class SubmitAnswersSuccesAction implements Action {
  readonly type = ActionTypes.SUBMIT_ANSWERS_SUCCESS;
}

export class ShowModalAction implements Action {
  readonly type = ActionTypes.SHOW_MODAL;
}

export class HideModalAction implements Action {
  readonly type = ActionTypes.HIDE_MODAL;
}


export class RetrySubmitRequestAction implements Action {
  readonly type = ActionTypes.RETRY_SUBMIT_REQUEST;
  constructor(public payload: { surveys: Array<Survey>, survey: Survey }) {}
}

export class RetrySubmitFailedAction implements Action {
  readonly type = ActionTypes.RETRY_SUBMIT_FAILED;
  constructor(public payload: { error: string }) {}
}

export class RetrySubmitSuccessAction implements Action {
  readonly type = ActionTypes.RETRY_SUBMIT_SUCCESS;
  constructor(public payload: { surveys: Array<Survey>, survey: Survey }) {}
}

export class ResetSubmittedIndexAction implements Action {
  readonly type = ActionTypes.RESET_SUBMITTED_INDEX;
}


// export class RetrySubmitSurveysRequestAction implements Action {
//   readonly type = ActionTypes.RETRY_SUBMIT_SURVEYS_REQUEST;
//   constructor(public payload: { surveys: Array<Survey> }) {}
// }
//
// export class RetrySubmitSurveysFailedAction implements Action {
//   readonly type = ActionTypes.RETRY_SUBMIT_SURVEYS_FAILED;
//   constructor(public payload: { error: string, survey: Survey }) {}
// }
//
// export class RetrySubmitSurveysSuccessAction implements Action {
//   readonly type = ActionTypes.RETRY_SUBMIT_SURVEYS_SUCCESS;
//   constructor(public payload: { survey: Survey }) {}
// }


export type Actions =
  | RequestAvailabilityAction
  | SuccessAvailabilityAction

  | LoadQuestionsRequestAction
  | LoadQuestionsFailedAction
  | LoadQuestionsSuccessAction

  | ChangeLanguageRequestAction
  | ChangeLanguageFailedAction
  | ChangeLanguageSuccessAction

  | NextQuestionAction
  | PreviousQuestionAction

  | ResetSurveyAction

  | SubmitAnswersRequestAction
  | SubmitAnswersFailedAction
  | SubmitAnswersSuccesAction

  | ShowModalAction
  | HideModalAction

  | RetrySubmitRequestAction
  | RetrySubmitFailedAction
  | RetrySubmitSuccessAction

  | ResetSubmittedIndexAction

  // | RetrySubmitSurveysRequestAction
  // | RetrySubmitSurveysFailedAction
  // | RetrySubmitSurveysSuccessAction
  ;
