import { Component, OnInit, Input } from '@angular/core';
import { NavigationItem } from 'src/app/modules/layout-module/models/navigation-item';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NavigationStoreSelectors } from '../../store';
import { Observable } from 'rxjs';
import { State } from '../../store/state';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  navigationItems$: Observable<Array<NavigationItem>>;

  constructor(private router: Router, private store$: Store<State>) {
    this.navigationItems$ = this.store$.select(NavigationStoreSelectors.selectAllNavigationItems);
  }

  ngOnInit() {}

  navigateTo(navigationItem: NavigationItem) {
    this.router.navigateByUrl(navigationItem.routingUrl);
  }
}
