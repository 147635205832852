import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, map, startWith, switchMap, withLatestFrom} from 'rxjs/operators';
import * as featureActions from './actions';
import {SurveyDataService} from '../services/survey-data.service';
import {SurveyState} from './state';
import {Language} from '../../../app.language';

@Injectable()
export class QuestionStoreEffects {

  @Effect()
  checkAvailabilityEffect$: Observable<Action> = this.actions$.pipe(
    ofType(featureActions.ActionTypes.GET_AVAILABILITY),
    map(() => new featureActions.SuccessAvailabilityAction({
        isAvailable: this.service.isAvailable()
      })
    )
  );

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType(featureActions.ActionTypes.LOAD_QUESTIONS_REQUEST),
    startWith(new featureActions.LoadQuestionsRequestAction(
      {language: Language.ENGLISH})
    ),
    map((action: featureActions.ChangeLanguageRequestAction) => action.payload),
    switchMap(payload =>

      this.service.getQuestions(payload.language).pipe(
        map(data =>
          new featureActions.LoadQuestionsSuccessAction({
            questions: data.questions
          })
        ),
        catchError((error: any) => of(
          new featureActions.LoadQuestionsFailedAction({error: error}))
        )
      )
    )
  );


  @Effect()
  changeLanguageEffect$: Observable<Action> = this.actions$.pipe(
    ofType(featureActions.ActionTypes.CHANGE_LANGUAGE_REQUEST),
    map((action: featureActions.ChangeLanguageRequestAction) => action.payload),
    switchMap(payload =>

      this.service.getQuestions(payload.language).pipe(
        map(values =>
          new featureActions.ChangeLanguageSuccessAction({
            questions: values.questions
          })
        ),
        catchError((error: any) => of(
          new featureActions.ChangeLanguageFailedAction({error: error}))
        )
      )
    )
  );

  @Effect()
  saveSubmitEffect$: Observable<Action> = this.actions$.pipe(
    ofType(featureActions.ActionTypes.SUBMIT_ANSWERS_REQUEST),
    withLatestFrom(this.store$),
    map(store => store[1]),
    switchMap((rootState: any) =>

      this.service.submitSurvey(rootState.questionState.survey).pipe(
        map(() => new featureActions.SubmitAnswersSuccesAction()),
        catchError((error: any) => of(
          new featureActions.SubmitAnswersFailedAction({
            error: error,
            survey: rootState.questionState.survey
          }),
          new featureActions.ShowModalAction()
          )
        )
      )
    )
  );

  @Effect()
  retrySubmitEffect$: Observable<Action> = this.actions$.pipe(
    ofType(featureActions.ActionTypes.RETRY_SUBMIT_REQUEST),
    map((action: featureActions.RetrySubmitRequestAction) => action.payload),
    switchMap(payload =>
      this.service.submitSurvey(payload.survey).pipe(
        map(() =>
          new featureActions.RetrySubmitSuccessAction({
            surveys: payload.surveys,
            survey: payload.survey
          })
        ),
        catchError((error: any) => of(
          new featureActions.RetrySubmitFailedAction({
            error: error
          })
          )
        )
      )
    )
  );

  // @Effect()
  // retrySubmitSurveysEffect$: Observable<Action> = this.actions$.pipe(
  //   ofType(featureActions.ActionTypes.RETRY_SUBMIT_SURVEYS_REQUEST),
  //   map((action: featureActions.RetrySubmitSurveysRequestAction) => action.payload),
  //   switchMap(payload => {
  //     let result = new Observable<any>();
  //
  //     payload.surveys.forEach(survey =>
  //       result = combineLatest(result,
  //         this.service.submitSurvey(survey).pipe(
  //           map(() => {
  //             return new featureActions.RetrySubmitSurveysSuccessAction({
  //               survey: survey
  //             });
  //           }),
  //           catchError((error: any) => of(
  //             new featureActions.RetrySubmitSurveysFailedAction({
  //               error: error,
  //               survey: survey
  //             })
  //             )
  //           )
  //         )
  //       )
  //     );
  //
  //     return result;
  //   })
  // );

  constructor(
    private actions$: Actions,
    private service: SurveyDataService,
    private store$: Store<SurveyState>
  ) {
  }
}
