import {EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InputValue} from '../../modules/input';


export class InputAssist implements OnInit {

  @Input()
  values: InputValue[];

  @Input()
  default: any;

  @Input()
  selected: InputValue;

  @Output()
  onValueGiven = new EventEmitter<InputValue>();

  ngOnInit(): void {
    if (this.default == null) {
      this.default = '';
    }
  }

  select(inputValue: InputValue): void {
    this.selected = inputValue;
    this.onValueGiven.emit(this.selected);
  }
}
