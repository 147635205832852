import {Component, Input} from '@angular/core';
import {InputAssist} from '../input-assist';


@Component({
  selector: 'app-input-option',
  templateUrl: './radio.component.html'
})

export class RadioComponent extends InputAssist {

  @Input()
  align: string;
}


