import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SidebarComponent } from './containers/sidebar/sidebar.component';
import { HeaderComponent } from './containers/header/header.component';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';
import { NavigationStoreModule } from './store';
import { AlertComponent } from './containers/alert/alert.component';
import { ClarityModule } from '@clr/angular';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ClarityModule,
    NavigationStoreModule
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    NavigationItemComponent,
    AlertComponent
  ],
  exports: [HeaderComponent, SidebarComponent, AlertComponent]
})
export class LayoutModule {}
