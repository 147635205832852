import {initialState, SurveyState} from './state';
import {Actions, ActionTypes} from './actions';
import {Language} from '../../../app.language';
import {Survey} from '../services/survey-data.service';
import * as Moment from 'moment';

export function questionReducer(
  state = initialState,
  action: Actions
): SurveyState {
  switch (action.type) {

    case ActionTypes.SET_AVAILABILITY: {
      return {
        ...state,
        isAvailable: action.payload.isAvailable
      };
    }

    case ActionTypes.LOAD_QUESTIONS_REQUEST: {
      return {
        ...state,
        isLoadingSurvey: true
      };
    }

    case ActionTypes.LOAD_QUESTIONS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        isLoadingSurvey: false
      };
    }

    case ActionTypes.LOAD_QUESTIONS_SUCCESS: {
      return {
        ...state,
        isLoadingSurvey: false,

        survey: new Survey(),
        questions: action.payload.questions,

        question:
          state.question !== null
            ? state.question
            : action.payload.questions !== null &&
            action.payload.questions.length > 0
            ? action.payload.questions[0]
            : null
      };
    }


    case ActionTypes.CHANGE_LANGUAGE_REQUEST: {
      return {
        ...state,
        error: null,
        isLoadingSurvey: true
      };
    }

    case ActionTypes.CHANGE_LANGUAGE_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        isLoadingSurvey: false
      };
    }

    case ActionTypes.CHANGE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        isLoadingSurvey: false,
        questions: action.payload.questions,
        question:
          state.question != null
            ? action.payload.questions.find(
            question => question.id === state.question.id
            )
            : null
      };
    }


    case ActionTypes.NEXT_QUESTION: {
      const response = state.survey;
      const data: any = action.payload.answer;
      let language = state.language;

      const last =
        action.payload.answer.nextId == null && state.question.nextId == null;

      const nextQuestion = last
        ? null
        : state.questions.find(
          question =>
            question.id ===
            (state.question.nextId == null
              ? state.question.answerChoices.find(
                answer => answer === action.payload.answer
              ).nextId
              : state.question.nextId)
        );

      response.answers[state.question.id] = action.payload.answer.value;

      if (state.question.timestamp != null) {
        response.timestamp = Moment().format();
      }

      if (data.language != null) {
        Object.values(Language).forEach(lang => {
          if (lang === data.language) {
            language = lang;
          }
        });
      } else if (data.result != null) {
        response.result = data.result;
      } else if (data.subject != null) {
        response.subject = data.subject;
      }

      return {
        ...state,
        questionIndex: ++state.questionIndex,

        isLastQuestion: last,
        question: nextQuestion,
        previousQuestion: state.question,

        language: language,
        survey: response
      };
    }

    case ActionTypes.PREVIOUS_QUESTION: {
      const question = state.previousQuestion;

      const previousId = Object.keys(state.survey.answers)
        [state.questionIndex - 2];

      const previous = state.questions.find(
        find => find.id === previousId);

      if (previous == null) {
        Object.keys(state.survey.answers).forEach(key => {
          if (key === question.id) {
            question.questionType.default = state.survey.answers[key];
          }
        });
      }

      return {
        ...state,
        questionIndex: --state.questionIndex,

        isLastQuestion: false,
        question: question,
        previousQuestion: previous
      };
    }


    case ActionTypes.RESET_SURVEY: {
      const question = state.questions[0];

      Object.keys(state.survey.answers).forEach(key => {
        if (key === question.id) {
          question.questionType.default = state.survey.answers[key];
        }
      });

      return {
        ...initialState,
        questions: state.questions,
        question: question,
        submittedIndex: state.submittedIndex,
        unsubmittedIndex: state.unsubmittedIndex,
        survey: new Survey(),
        language: state.language
      };
    }


    case ActionTypes.SUBMIT_ANSWERS_REQUEST: {
      return {
        ...state,
        isLoadingSurvey: true
      };
    }

    case ActionTypes.SUBMIT_ANSWERS_FAILED: {
      const survey = action.payload.survey;
      let surveys = (JSON.parse(
        window.localStorage.getItem(
          'surveys')) as Array<Survey>);

      survey.answers = Object.assign(
        {}, survey.answers);

      if (surveys != null) {
        surveys.push(survey);
      } else {
        surveys = new Array<Survey>(survey);
      }

      window.localStorage.setItem(
        'surveys', JSON.stringify(surveys));

      return {
        ...state,
        error: action.payload.error,
        isLoadingSurvey: false,
        unsubmittedIndex: ++state.unsubmittedIndex
      };
    }

    case ActionTypes.SUBMIT_ANSWERS_SUCCESS: {
      const question = state.questions[0];
      const surveyIndex = ++state.submittedIndex;

      window.localStorage.setItem(
        'submittedIndex', surveyIndex.toString());

      Object.keys(state.survey.answers).forEach(key => {
        if (key === question.id) {
          question.questionType.default = state.survey.answers[key];
        }
      });

      return {
        ...initialState,
        questions: state.questions,
        question: question,
        submittedIndex: surveyIndex,
        unsubmittedIndex: state.unsubmittedIndex,
        survey: new Survey(),
        language: state.language
      };
    }


    case ActionTypes.SHOW_MODAL: {
      return {
        ...state,
        showModal: true
      };
    }


    case ActionTypes.HIDE_MODAL: {
      return {
        ...state,
        showModal: false
      };
    }


    case ActionTypes.RETRY_SUBMIT_REQUEST: {
      return {
        ...state,
        isLoadingRetry: true,
      };
    }

    case ActionTypes.RETRY_SUBMIT_FAILED: {
      return {
        ...state,
        isLoadingRetry: false,
        error: action.payload.error
      };
    }

    case ActionTypes.RETRY_SUBMIT_SUCCESS: {
      const surveys = action.payload.surveys;

      if (surveys != null) {
        surveys.splice(surveys.indexOf(action.payload.survey), 1);
        window.localStorage.setItem('surveys', JSON.stringify(surveys));
      }

      return {
        ...state,
        isLoadingRetry: false,
        unsubmittedIndex: --state.unsubmittedIndex
      };
    }


    // case ActionTypes.RETRY_SUBMIT_SURVEYS_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingRetry: true,
    //   };
    // }
    //
    // case ActionTypes.RETRY_SUBMIT_SURVEYS_FAILED: {
    //   return {
    //     ...state,
    //     isLoadingRetry: false,
    //     error: action.payload.error
    //   };
    // }
    //
    // case ActionTypes.RETRY_SUBMIT_SURVEYS_SUCCESS: {
    //   const surveys = JSON.parse(
    //     window.localStorage.getItem('surveys')) as Array<Survey>;
    //
    //   if (surveys != null) {
    //     surveys.splice(surveys.indexOf(action.payload.survey), 1);
    //     window.localStorage.setItem('surveys', JSON.stringify(surveys));
    //   }
    //
    //   return {
    //     ...state,
    //     isLoadingRetry: false,
    //     unsubmittedIndex: --state.unsubmittedIndex
    //   };
    // }

    case ActionTypes.RESET_SUBMITTED_INDEX: {
      window.localStorage.setItem('submittedIndex', '0');

      return {
        ...state,
        submittedIndex: 0
      };
    }


    default: {
      return state;
    }
  }
}
