import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NavigationStoreSelectors } from '../../store';
import { State } from '../../store/state';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  showAlert$: Observable<boolean>;
  message$: Observable<string>;

  constructor(private store$: Store<State>) {
    this.showAlert$ = this.store$.select(NavigationStoreSelectors.showAlert);
    this.message$ = this.store$.select(NavigationStoreSelectors.selectError);
  }

  ngOnInit() {}
}
