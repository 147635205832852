import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Question} from '../models/question';
import {Language} from '../../../app.language';
import {Survey} from '../services/survey-data.service';


export const featureAdapter:
  EntityAdapter<Question> = createEntityAdapter<Question>(
  {
    selectId:
      model => model.id,

    sortComparer:
      (a: Question, b: Question):
        number => b.id.localeCompare(a.id)
  });

export interface SurveyState extends EntityState<Question> {

  questions: Array<Question>;

  error: any;
  showModal: boolean;

  isAvailable: boolean;
  isLoadingSurvey: boolean;
  isLoadingRetry: boolean;

  isLastQuestion: boolean;
  isCompletedSurvey: boolean;

  questionIndex: number;
  submittedIndex: number;
  unsubmittedIndex: number;

  question: Question;
  previousQuestion: Question;

  survey?: Survey;
  language: Language;
}

export const initialState:
  SurveyState = featureAdapter.getInitialState({

  questions: [],

  error: null,
  showModal: false,

  isAvailable: false,
  isLoadingSurvey: false,
  isLoadingRetry: false,

  isLastQuestion: false,
  isCompletedSurvey: false,

  questionIndex: 0,
  submittedIndex: Number(window.localStorage.getItem('submittedIndex')),
  unsubmittedIndex: JSON.parse(window.localStorage.getItem('surveys')) != null
    ? (JSON.parse(window.localStorage.getItem('surveys')) as Array<Survey>).length
    : 0,

  question: null,
  previousQuestion: null,

  language: Language.ENGLISH
});
