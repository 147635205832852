import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {SurveyState} from '../store/state';
import {QuestionStoreActions} from '../store';
import {selectIsAvailable} from '../store/selectors';


@Component({
  selector: 'app-survey-page',
  templateUrl: './survey-page.component.html'
})

export class SurveyPageComponent implements OnInit {

  constructor(public store$: Store<SurveyState>,
              private router: Router
  ) {
  }

  ngOnInit(): void {
    this.store$.dispatch(new QuestionStoreActions.RequestAvailabilityAction());
    this.store$.pipe(select(selectIsAvailable)).subscribe(isAvailable => {
      const route = !isAvailable ? '/unavailable' : '/';
      this.router.navigateByUrl(route);
    });
  }
}
