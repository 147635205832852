import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {Question} from '../models/question';
import {SurveyState} from './state';
import {Language} from '../../../app.language';


export const selectState: MemoizedSelector<object, SurveyState>
  = createFeatureSelector<SurveyState>('questionState');

export const selectError: MemoizedSelector<object, any>
  = createSelector(selectState, (state: SurveyState): any => state.error);

export const selectShowError: MemoizedSelector<object, boolean>
  = createSelector(selectState, (state: SurveyState): boolean => state.showModal);


export const selectIsAvailable: MemoizedSelector<object, boolean>
  = createSelector(selectState,
  (state: SurveyState): boolean => state.isAvailable);


export const selectIsLoadingSurvey: MemoizedSelector<object, boolean>
  = createSelector(selectState,
  (state: SurveyState): boolean => state.isLoadingSurvey);

export const selectIsLoadingRetry: MemoizedSelector<object, boolean>
  = createSelector(selectState,
  (state: SurveyState): boolean => state.isLoadingRetry);


export const selectIsCompletedSurvey: MemoizedSelector<object, boolean>
  = createSelector(selectState,
  (state: SurveyState): boolean => state.isCompletedSurvey);

export const selectIsLastQuestion: MemoizedSelector<object, boolean>
  = createSelector(selectState,
  (state: SurveyState): boolean => state.isLastQuestion);


export const selectProgressLength: MemoizedSelector<object, number>
  = createSelector(selectState,
  (state: SurveyState): number => state.questions.length);

export const selectQuestionIndex: MemoizedSelector<object, number>
  = createSelector(selectState,
  (state: SurveyState): number => state.questionIndex);

export const selectSubmittedIndex: MemoizedSelector<object, number>
  = createSelector(selectState,
  (state: SurveyState): number => state.submittedIndex);

export const selectUnsubmittedInddex: MemoizedSelector<object, number>
  = createSelector(selectState,
  (state: SurveyState): number => state.unsubmittedIndex);


export const selectQuestion: MemoizedSelector<object, Question>
  = createSelector(selectState,
  (state: SurveyState): Question => state.question);

export const selectPreviousQuestiom: MemoizedSelector<object, Question>
  = createSelector(selectState,
  (state: SurveyState): Question => state.previousQuestion);

export const selectLanguage: MemoizedSelector<object, Language>
  = createSelector(selectState,
  (state: SurveyState): Language => state.language);
