import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-card-content',
  templateUrl: './card-content.component.html'
})

export class CardContentComponent {

  @Input()
  title: string;

  @Input()
  description: string;
}
