import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { NavigationItem } from 'src/app/modules/layout-module/models/navigation-item';

export const featureAdapter: EntityAdapter<
  NavigationItem
> = createEntityAdapter<NavigationItem>({
  selectId: model => model.id,
  sortComparer: (a: NavigationItem, b: NavigationItem): number =>
    b.id.toString().localeCompare(a.id)
});

export interface State extends EntityState<NavigationItem> {
  isLoading?: boolean;
  error?: any;
  showAlert: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  showAlert: false
});
