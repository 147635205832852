import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-unavailable',
  templateUrl: './unavailable.component.html',
  styleUrls: ['./unavailable.component.scss']
})

export class UnavailableComponent implements OnInit {

  ngOnInit() {

  }
}
