import {Routes} from '@angular/router';
import {SurveyPageComponent} from './modules/survey-module/page/survey-page.component';
import {SurveyComponent} from './modules/survey-module/containers/survey/survey.component';
import {UnavailableComponent} from './modules/survey-module/containers/unavailable/unavailable.component';

export const routes: Routes = [
  {
    path: '',
    component: SurveyPageComponent,
    children: [
      {
        path: '',
        component: SurveyComponent
      },
      {
        path: 'unavailable',
        component: UnavailableComponent
      }
    ]
  }
];
