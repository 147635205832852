import {Component, Input} from '@angular/core';
import {InputAssist} from '../input-assist';
import {InputValue} from '../../../modules/input';


@Component({
  selector: 'app-intput-button',
  templateUrl: './button.component.html'
})

export class ButtonComponent extends InputAssist {

  @Input()
  input: InputValue;

  @Input()
  hasSelectOption = false;

  isSelected = false;

  select(): void {
    this.isSelected = !this.isSelected;
    super.select(this.input);
  }
}


