import {Component, ComponentFactoryResolver, EventEmitter, Input, OnChanges, Output, Type, ViewChild} from '@angular/core';
import {InputDirective} from './input.directive';
import {DropdownComponent} from './dropdown/dropdown.component';
import {RadioComponent} from './radio/radio.component';
import {InputType, InputValue} from '../../modules/input';
import {LabelComponent} from './label/label.component';
import {TextComponent} from './text/text.component';


@Component({
  selector: 'app-input',
  templateUrl: './input-manager.component.html'
})

export class InputManagerComponent implements OnChanges {

  @Input()
  inputType: InputType;

  @Input()
  inputValues: InputValue[];

  @ViewChild(InputDirective)
  inputHost: InputDirective;

  @Output()
  onValueGiven = new EventEmitter<InputValue>();


  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnChanges() {
    let instance;
    switch (this.inputType.type) {

      case 'radio':
        instance = this.getInstance<RadioComponent>(RadioComponent);
        instance.align = this.inputType.align;
        break;

      // case 'option':
      //   instance = this.getInstance<OptionComponent>(OptionComponent);
      //   instance.align = this.inputType.align;
      //   break;

      case 'dropdown':
        instance = this.getInstance<DropdownComponent>(DropdownComponent);
        instance.placeholder = this.inputType.placeholder;
        break;

      case 'label':
        instance = this.getInstance<LabelComponent>(LabelComponent);
        instance.placeholder = this.inputType.placeholder;
        instance.default = this.inputType.default;
        break;

      case 'text':
        instance = this.getInstance<TextComponent>(TextComponent);
        instance.placeholder = this.inputType.placeholder;
        break;
    }

    instance.values = this.inputValues;
    instance.onValueGiven.subscribe(
      result => this.onValueGiven.emit(result));
  }

  private getInstance<T>(component: Type<T>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const viewContainerRef = this.inputHost.viewReference;
    viewContainerRef.clear();

    return viewContainerRef
      .createComponent<T>(componentFactory)
      .instance;
  }
}
