import {Component, Input} from '@angular/core';
import {InputAssist} from '../input-assist';


@Component({
  selector: 'app-input-label',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})

export class TextComponent extends InputAssist {

  @Input()
  placeholder: string;

  ngOnInit(): void {
    this.onValueGiven.emit(this.values[0]);
  }

  setText(text: string) {
    this.values[0].value = text;
    this.onValueGiven.emit(this.values[0]);
  }
}

