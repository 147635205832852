import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SurveyComponent} from './containers/survey/survey.component';
import {SurveyPageComponent} from './page/survey-page.component';
import {SharedModule} from 'src/app/shared/shared.module';
import {RouterModule} from '@angular/router';
import {routes} from '../../app-routes';
import {QuestionStoreModule} from './store';
import {ClarityModule} from '@clr/angular';
import {LayoutModule} from '../layout-module/layout.module';
import {ModalComponent} from './containers/modal/modal.component';
import {UnavailableComponent} from './containers/unavailable/unavailable.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    QuestionStoreModule,
    ClarityModule,
    RouterModule.forChild(routes),
    LayoutModule
  ],
  declarations: [
    SurveyComponent,
    SurveyPageComponent,
    ModalComponent,
    UnavailableComponent
  ],
  exports: []
})
export class SurveyModule {}
