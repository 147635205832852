import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {questionReducer} from './reducer';
import {EffectsModule} from '@ngrx/effects';
import {QuestionStoreEffects} from './effects';


@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('questionState', questionReducer),
    EffectsModule.forFeature([QuestionStoreEffects])
  ],
  declarations: [],
  providers: [QuestionStoreEffects],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class QuestionStoreModule {}
