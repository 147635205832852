import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Language} from '../../../app.language';


@Component({
  selector: 'app-language-selector',
  templateUrl: './language.component.html'
})

export class LanguageComponent implements OnInit {

  languages = Object.values(Language);

  @Output()
  changedLanguage = new EventEmitter<Language>();


  ngOnInit() {}

  setLanguage(language: Language): void {
    this.changedLanguage.emit(language);
  }
}
