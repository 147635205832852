import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Question } from '../models/question';
import { delay, flatMap, retryWhen } from 'rxjs/operators';
import { AppConfig } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class SurveyDataService {
  private QUESTIONS_PATH = './assets/lang/questions/';
  private QUESTIONS_SUFFIX = '.json';
  private POST_SURVEY = 'api/survey/v1/inose-training?collection=bac';

  constructor(private http: HttpClient, private config: AppConfig) {}

  isAvailable(): any {
    return this.config.get('available');
  }

  getQuestions(language: string): Observable<any> {
    return this.http.get<Array<Question>>(
      this.QUESTIONS_PATH + language.toLowerCase() + this.QUESTIONS_SUFFIX
    );
  }

  submitSurvey(survey: Survey): Observable<any> {
    survey.answers = Object.assign({}, survey.answers);

    return this.http
      .post(
        this.config.get('baseUrl') + this.POST_SURVEY,
        JSON.stringify(survey)
      )
      .pipe(
        retryWhen(error => {
          const retries = 3;
          let retry = 1;

          return error.pipe(
            flatMap(response => {
              return retry++ < retries
                ? of(response.status).pipe(delay(1000))
                : throwError(response);
            })
          );
        })
      );
  }
}

export class Survey {
  survey = {
    code: 'inose_training_sanitair',
    label: 'Inose Training Sanitair',
    version: 'v1'
  };

  timestamp = null;
  subject = null;
  result = null;
  answers: any = [];
}
