import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {selectError, selectShowError} from '../../store/selectors';
import {QuestionStoreActions} from '../../store';
import {SurveyState} from '../../store/state';


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})

export class ModalComponent implements OnInit {

  error$: Observable<any>;
  showModal$: Observable<boolean>;


  constructor(public store$: Store<SurveyState>) {}

  ngOnInit() {
    this.error$ = this.store$.pipe(select(selectError));
    this.showModal$ = this.store$.pipe(select(selectShowError));
  }

  hideModal() {
    this.store$.dispatch(
      new QuestionStoreActions.HideModalAction());
  }
}
