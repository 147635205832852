import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html'
})

export class ProgressBarComponent implements OnInit {

  @Input()
  index: number;

  @Input()
  length: number;

  @Input()
  isLast: boolean;

  ngOnInit() {}
}
