import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as featureActions from './actions';

@Injectable()
export class NavigationStoreEffects {
  constructor(private actions$: Actions) {}

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType<featureActions.LoadNavigationsRequestAction>(
      featureActions.ActionTypes.LOAD_REQUEST
    ),
    startWith(new featureActions.LoadNavigationsRequestAction()),
    map(
      action =>
        new featureActions.LoadNaviationsSuccessAction({
          items: [
            {
              id: '1',
              routingUrl: 'questions',
              translationKey: 'Questions',
              children: []
            },
            {
              id: '2',
              routingUrl: 'info',
              translationKey: 'Answers',
              children: []
            }
          ]
        })
    )
  );
}
